<div class="page-container flex flex-col gap-2">
    <div class="section-title flex-none">Redeem Invite</div>
    <mat-card appearance="outlined" class="link-card flex flex-auto flex-row items-center justify-center">
        <mat-card-content class="!flex flex-auto flex-row items-center justify-center">
            <div class="flex flex-auto flex-col items-center justify-center">
                <img class="flex-auto" src="/assets/svg/graphic-redeem.svg" width="400" height="300" />
                <div class="flex w-full flex-auto flex-col gap-4">
                    <mat-form-field class="mat-form-field-no-hint" appearance="outline" color="accent">
                        <input matInput name="code" placeholder="Invite Code" #code [disabled]="redeemingCode" />
                    </mat-form-field>

                    <button
                        mat-flat-button
                        color="purple"
                        (click)="redeemInviteCode(code.value)"
                        [disabled]="redeemingCode || !(code.value.length === 10 || code.value.length === 15)"
                    >
                        REDEEM
                    </button>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
