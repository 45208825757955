import { NgOptimizedImage } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { NavigationEnd, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import {
    MeDetailedQuery,
    MeDetailedQueryGQL,
    MeDetailedQueryVariables,
    ProviderActiveModulesFragment,
    ProviderQueryGQL,
    ProviderSimpleFragment,
    ProviderTypeDisplayStrings,
    ProviderWithPermissionsFragment,
    UserDataDetailedFragment
} from '@mymahi/api';
import { AuthService } from '@mymahi/authentication';
import { QueryRef } from 'apollo-angular';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { NavbarContainerComponent } from './navbar-container/container.component';

@Component({
    selector: 'business-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [
        NavbarContainerComponent,
        MatListModule,
        NgOptimizedImage,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        RouterLink,
        RouterLinkActive,
        MatDividerModule,
        RouterOutlet
    ]
})
export class AppComponent implements OnInit, OnDestroy {
    private ngUnsubscribe: Subject<void> = new Subject();
    private meDetailedWatch?: QueryRef<MeDetailedQuery, MeDetailedQueryVariables>;

    public profileLink: string;

    public providerId?: string;
    public meData?: UserDataDetailedFragment;
    public activeRewardsPage = false;
    public activeBadgesPage = false;
    public providersWithMembership: ProviderWithPermissionsFragment[] = [];
    public get selectedProvider(): ProviderWithPermissionsFragment | undefined {
        return this.meData ? this.meData.providersWithMembership.find((x) => x.id === this.providerId) : undefined;
    }

    constructor(
        private router: Router,
        private authService: AuthService,
        private meDetailed: MeDetailedQueryGQL,
        private providerQuery: ProviderQueryGQL
    ) {
        this.profileLink = `https://app.${environment.baseUrl}/profile`;
        router.events
            .pipe(
                filter((x) => x instanceof NavigationEnd),
                map((_x) => {
                    let snapshot = this.router.routerState.snapshot.root;
                    while (snapshot.firstChild) {
                        snapshot = snapshot.firstChild;
                    }
                    return snapshot;
                })
            )
            .subscribe((res) => {
                const providerId = res.paramMap.get('providerId');
                if (providerId != null) {
                    this.providerId = providerId;
                    this.checkModule();
                }
            });
    }

    public get isAuthRoute(): boolean {
        return this.router.isActive('/auth', { paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored' });
    }

    private checkModule() {
        this.providerQuery
            .fetch({
                providerId: this.providerId ?? '',
                withActiveModules: true
            })
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((res) => {
                if (res.data == null || res.errors != null) {
                    return;
                }

                const provider = res.data.provider as ProviderActiveModulesFragment;
                this.activeRewardsPage = provider.activeModules.some((x) => x.title === 'mentor_rewards');
                this.activeBadgesPage = provider.activeModules.some((x) => x.title === 'badge_templates');
            });
    }

    public ngOnInit(): void {
        this.authService.isAuthenticated$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((authenticated) => {
            if (authenticated) {
                this.meDetailedWatch = this.meDetailed.watch();
                this.meDetailedWatch.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
                    if (res.data == null || res.errors != null) {
                        return;
                    }
                    this.providersWithMembership = res.data.me.providersWithMembership.filter(
                        (x) =>
                            x.canAccessMentorFeature ||
                            x.canAccessOpportunityFeature ||
                            x.canAccessAdminFeature ||
                            x.canAccessNewsfeedFeature ||
                            x.canAccessMentorRewardFeature ||
                            x.canAccessBadgeTemplatesFeature
                    );

                    this.meData = res.data.me;
                    if (this.providerId == null && this.providersWithMembership.length > 0) {
                        this.providerId = this.providersWithMembership[0].id;
                    }
                });
                this.meDetailedWatch.startPolling(60000);
            }
        });
    }

    public ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    public getProviderTypeDisplayString(selectedProvider: ProviderSimpleFragment) {
        return ProviderTypeDisplayStrings.value[selectedProvider.providerType];
    }

    public selectProvider(selectedProvider: ProviderSimpleFragment) {
        this.router.navigate(['provider', selectedProvider.id, 'details']);
    }
}
