import { OverlayContainer } from '@angular/cdk/overlay';
import { IMAGE_LOADER, ImageLoaderConfig } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, inject, provideEnvironmentInitializer } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading, withRouterConfig } from '@angular/router';
import { APIModule } from '@mymahi/api';
import { AuthenticationModule } from '@mymahi/authentication';
import { provideDeviceSize } from '@mymahi/device-size';
import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { RedirectToProviderGuard } from './redirectToProvider.guard';

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(
            ReactiveFormsModule,
            MatLuxonDateModule,
            AuthenticationModule.forRoot(environment.auth),
            APIModule.forRoot({
                ENDPOINT: environment.api.graphql,
                ENDPOINT_WS: environment.api.graphql_ws
            })
        ),
        RedirectToProviderGuard,
        provideAnimations(),
        provideRouter(
            routes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
            withRouterConfig({ paramsInheritanceStrategy: 'always' })
        ),
        provideHttpClient(withInterceptorsFromDi()),
        provideDeviceSize(),
        {
            provide: IMAGE_LOADER,
            useValue: (config: ImageLoaderConfig): string => {
                const cdnImageUrl = `https://cdn.${environment.baseUrl}/images/`;

                // It's not a CDN url so just return src and let the browser handle it
                if (!config.src.startsWith(cdnImageUrl)) {
                    return config.src;
                }

                const cdnUrl = new URL(config.src);

                // Always default format to auto
                if (!cdnUrl.searchParams.has('f')) {
                    cdnUrl.searchParams.set('f', 'auto');
                }

                // Always default quality to 80
                if (!cdnUrl.searchParams.has('q')) {
                    cdnUrl.searchParams.set('q', '80');
                }

                if (config.width != null && config.width > 0) {
                    cdnUrl.searchParams.set('w', config.width.toString());
                }

                const loaderParams = config.loaderParams;
                if (loaderParams != null) {
                    const validParams = new Map<string, string>([
                        ['height', 'h'],
                        ['h', 'h'],
                        ['format', 'f'],
                        ['f', 'f'],
                        ['quality', 'q'],
                        ['q', 'q'],
                        ['align', 'a'],
                        ['a', 'a'],
                        ['fit', 'fit'],
                        ['withoutEnlargement', 'we']
                    ]);

                    for (const [option, param] of validParams) {
                        const value = loaderParams[option];
                        if (value != null) {
                            cdnUrl.searchParams.set(param, value.toString());
                        }
                    }

                    const square = loaderParams.square;
                    if (square === true && config.width != null) {
                        cdnUrl.searchParams.set('h', config.width.toString());
                    }

                    const ratio = loaderParams.ratio;
                    if (ratio != null && !Number.isNaN(+ratio) && config.width != null) {
                        cdnUrl.searchParams.set('h', (config.width / +ratio).toString());
                    }
                }

                return cdnUrl.toString();
            }
        },
        provideEnvironmentInitializer(() => inject(OverlayContainer).getContainerElement().classList.add('mat-typography'))
    ]
};
