import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { environment } from '../../environments/environment';

@Component({
    selector: 'business-mentor',
    templateUrl: './mentor.component.html',
    styleUrls: ['./mentor.component.scss'],
    imports: [MatCardModule, MatButtonModule]
})
export class MentorComponent {
    public mentorLink: string;

    constructor() {
        this.mentorLink = `https://app.${environment.baseUrl}/mentor`;
    }

    public openMentorDashboard() {
        window.open(this.mentorLink, '_blank');
    }
}
