import { enableProdMode } from '@angular/core';
import { FormControlDirective, FormControlName } from '@angular/forms';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

// FIXME: find a way to get rid of this hack
/* eslint-disable @typescript-eslint/no-explicit-any */
const originFormControlNgOnChanges = FormControlDirective.prototype.ngOnChanges;
FormControlDirective.prototype.ngOnChanges = function () {
    (this.form as any).nativeElement = (this.valueAccessor as any)._elementRef.nativeElement;
    // eslint-disable-next-line prefer-rest-params
    return originFormControlNgOnChanges.apply(this, arguments as any);
};

const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
FormControlName.prototype.ngOnChanges = function () {
    // eslint-disable-next-line prefer-rest-params
    const result = originFormControlNameNgOnChanges.apply(this, arguments as any);
    if ((this.valueAccessor as any)._elementRef) {
        (this.control as any).nativeElement = (this.valueAccessor as any)._elementRef.nativeElement;
    }
    return result;
};
/* eslint-enable @typescript-eslint/no-explicit-any */

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
