<business-navbar-container appTitle="Admin" [providersWithMembership]="providersWithMembership">
    @if (!isAuthRoute && providersWithMembership != null && providersWithMembership.length > 0 && selectedProvider) {
        <div class="app-left-menu flex flex-col gap-2">
            <div class="section flex-none">Provider &amp; User Information</div>
            <mat-list class="sub-section flex flex-col">
                <mat-list-item class="provider-menu">
                    <div class="flex flex-row items-center justify-between">
                        <div class="flex max-w-[45px] flex-auto basis-[45px] flex-row items-center">
                            <img
                                class="avatar small"
                                [ngSrc]="selectedProvider.logoImageUrl"
                                width="45"
                                height="45"
                                [loaderParams]="{ square: true }"
                                placeholder
                                alt="Logo Image"
                            />
                        </div>
                        <div class="flex flex-auto flex-col items-start justify-center pl-2.5">
                            <span class="provider-name">{{ selectedProvider.title }}</span>
                            <span class="provider-type">{{ getProviderTypeDisplayString(selectedProvider) }}</span>
                        </div>
                        <a mat-icon-button [matMenuTriggerFor]="menuSelectProvider" (click)="$event.stopPropagation()">
                            <mat-icon color="purple" fontSet="fas" fontIcon="fa-caret-down" class="leading-[unset]"></mat-icon>
                        </a>
                        <mat-menu #menuSelectProvider="matMenu" class="mat-menu-provider-select">
                            @for (provider of providersWithMembership; track provider; let i = $index) {
                                <a class="provider-menu !flex flex-row" mat-menu-item (click)="selectProvider(provider)">
                                    <div class="flex flex-row items-center justify-between">
                                        <div class="flex-none items-center">
                                            <img
                                                class="avatar small"
                                                [ngSrc]="provider.logoImageUrl"
                                                width="45"
                                                height="45"
                                                [loaderParams]="{ square: true }"
                                                placeholder
                                                alt="Logo Image"
                                            />
                                        </div>
                                        <div class="flex-auto pl-2.5">
                                            <span class="provider-list">{{ provider.title }}</span>
                                        </div>
                                    </div>
                                </a>
                            }
                        </mat-menu>
                    </div>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item class="provider-menu">
                    @if (meData) {
                        <div class="flex flex-row items-center justify-between">
                            <div class="flex max-w-[45px] flex-auto basis-[45px] flex-row items-center">
                                <img
                                    class="avatar small"
                                    width="45"
                                    height="45"
                                    [loaderParams]="{ square: true }"
                                    [ngSrc]="meData.pictureUrl || '/assets/svg/default_avatar.svg'"
                                    placeholder
                                    alt="User Image"
                                />
                            </div>
                            <div class="flex flex-auto flex-col items-start justify-center pl-2.5">
                                <span class="user-name">{{ meData.fullName }}</span>
                            </div>
                            <a mat-icon-button class="flex-none items-center justify-center" [matMenuTriggerFor]="menuSelectProvider">
                                <mat-icon color="purple" fontSet="fas" fontIcon="fa-caret-down"></mat-icon>
                            </a>
                            <mat-menu #menuSelectProvider="matMenu" class="mat-menu-provider-select">
                                <a mat-menu-item class="provider-menu flex flex-row" [href]="profileLink" target="_blank">
                                    <span class="provider-list">User Profile</span>
                                </a>
                            </mat-menu>
                        </div>
                    }
                </mat-list-item>
            </mat-list>
            <div class="section flex-none">Area</div>
            <mat-nav-list class="sub-section flex flex-col">
                <button
                    mat-list-item
                    class="menu-item"
                    routerLink="/provider/{{ selectedProvider.id }}/details"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: false }"
                >
                    <span>Details</span>
                </button>
                <mat-divider></mat-divider>
                <button
                    mat-list-item
                    class="menu-item"
                    routerLink="/provider/{{ selectedProvider.id }}/my-school-information"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: false }"
                >
                    <span>School Information</span>
                </button>
                <mat-divider></mat-divider>
                <button
                    mat-list-item
                    class="menu-item"
                    routerLink="/provider/{{ selectedProvider.id }}/newsfeed"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: false }"
                    [disabled]="!selectedProvider.canAccessNewsfeedFeature"
                >
                    <span>Newsfeed</span>
                </button>
                <mat-divider></mat-divider>
                <button
                    mat-list-item
                    class="menu-item"
                    routerLink="/provider/{{ selectedProvider.id }}/opportunity"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: false }"
                    [disabled]="!selectedProvider.canAccessOpportunityFeature"
                >
                    <span>Opportunities</span>
                </button>
                <mat-divider></mat-divider>
                <button
                    mat-list-item
                    class="menu-item"
                    routerLink="/redeem"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: false }"
                >
                    <span>Redeem Invite</span>
                </button>
                <mat-divider></mat-divider>
                <button
                    mat-list-item
                    class="menu-item"
                    routerLink="/provider/{{ selectedProvider.id }}/mentor"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: false }"
                    [disabled]="!selectedProvider.canAccessMentorFeature"
                >
                    <span>Mentor Dashboard</span>
                </button>
            </mat-nav-list>
            @if (selectedProvider.canAccessAdminFeature) {
                <ng-container class="flex-auto">
                    <div class="section flex-none">Administration</div>
                    <mat-nav-list class="sub-section flex flex-col">
                        <button
                            mat-list-item
                            class="menu-item"
                            routerLink="/provider/{{ selectedProvider.id }}/admin/access-management"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: false }"
                        >
                            <span>Access Management</span>
                        </button>
                        <mat-divider></mat-divider>
                        <button
                            mat-list-item
                            class="menu-item"
                            routerLink="/provider/{{ selectedProvider.id }}/admin/integrations"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: false }"
                        >
                            <span>Integrations</span>
                        </button>
                        <mat-divider></mat-divider>
                        <button
                            mat-list-item
                            class="menu-item"
                            routerLink="/provider/{{ selectedProvider.id }}/admin/import-mentor-groups"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: false }"
                            [disabled]="!selectedProvider.canImportMentorGroupsFromLearnerSmsData"
                        >
                            <span>Import Mentor Groups</span>
                        </button>
                        <mat-divider></mat-divider>
                        <button
                            mat-list-item
                            class="menu-item"
                            routerLink="/provider/{{ selectedProvider.id }}/admin/rewards"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: false }"
                            [disabled]="!activeRewardsPage"
                        >
                            <span>Rewards</span>
                        </button>
                        <mat-divider></mat-divider>
                        <button
                            mat-list-item
                            class="menu-item"
                            routerLink="/provider/{{ selectedProvider.id }}/admin/badges"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: false }"
                            [disabled]="!activeBadgesPage"
                        >
                            <span>Badges</span>
                        </button>
                        <mat-divider></mat-divider>
                        <button
                            mat-list-item
                            class="menu-item"
                            routerLink="/provider/{{ selectedProvider.id }}/admin/manage-modules"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: false }"
                        >
                            <span>Manage Modules</span>
                        </button>
                        <mat-divider></mat-divider>
                        <button
                            mat-list-item
                            class="menu-item"
                            routerLink="/provider/{{ selectedProvider.id }}/admin/settings"
                            routerLinkActive="active"
                            [routerLinkActiveOptions]="{ exact: false }"
                        >
                            <span>Settings</span>
                        </button>
                    </mat-nav-list>
                </ng-container>
            }
        </div>
    }

    @if (!isAuthRoute && !(providersWithMembership != null && providersWithMembership.length > 0)) {
        <div class="app-left-menu flex flex-col gap-2">
            <div class="section flex-none">User Information</div>
            <mat-list class="sub-section flex flex-col">
                <mat-list-item class="provider-menu">
                    @if (meData) {
                        <div class="flex flex-row items-center justify-start">
                            <div class="flex basis-[45px] flex-row items-center">
                                <img
                                    [ngSrc]="meData.pictureUrl || '/assets/svg/default_avatar.svg'"
                                    width="45"
                                    height="45"
                                    [loaderParams]="{ square: true }"
                                    placeholder
                                    class="avatar small"
                                />
                            </div>
                            <div class="flex flex-auto flex-col items-start justify-center pl-2.5">
                                <span class="user-name">{{ meData.fullName }}</span>
                            </div>
                        </div>
                    }
                </mat-list-item>
            </mat-list>
            <div class="section flex-none">Area</div>
            <mat-nav-list class="sub-section flex flex-col">
                <button mat-list-item class="menu-item" routerLink="/redeem" routerLinkActive="active">Redeem Invite</button>
            </mat-nav-list>
        </div>
    }
    <router-outlet></router-outlet>
</business-navbar-container>
