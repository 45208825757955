import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ApolloError } from '@apollo/client/errors';
import { MeDetailedQueryGQL } from '@mymahi/api';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class RedirectToProviderGuard {
    constructor(
        private meDetailed: MeDetailedQueryGQL,
        private router: Router
    ) {}

    public canActivate(
        _route: ActivatedRouteSnapshot,
        _state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.meDetailed.fetch().pipe(
            map((res) => {
                if (res.data == null || res.errors != null) {
                    return this.router.parseUrl(`/redeem`);
                }

                const provider = res.data.me.providersWithMembership.find(
                    (x) =>
                        x.canAccessMentorFeature || x.canAccessOpportunityFeature || x.canAccessAdminFeature || x.canAccessNewsfeedFeature
                );

                if (provider == null) {
                    return this.router.parseUrl(`/redeem`);
                } else {
                    return this.router.parseUrl(`/provider/${provider.id}/details`);
                }
            }),
            catchError((err) => {
                if (err instanceof ApolloError && err.networkError instanceof HttpErrorResponse && err.networkError.status === 401) {
                    return of(this.router.parseUrl(`/auth/logout`));
                } else {
                    return throwError(() => err);
                }
            })
        );
    }
}
