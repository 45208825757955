import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { formatNumber } from '@angular/common';
import { Directive, ElementRef, EventEmitter, Inject, Input, LOCALE_ID, OnDestroy } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    ValidatorFn,
    Validators
} from '@angular/forms';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';

const REGEX_CURRENCY_NUMBER_INPUT = /^[0-9]+(\.[0-9]{1,2})?$/;

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'input[currencyInput]',
    exportAs: 'currencyInput',
    host: {
        '[disabled]': 'disabled',
        '(input)': '_onInput($event.target.value)',
        '(blur)': '_onBlur()'
    },
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: CurrencyInputDirective, multi: true },
        { provide: NG_VALIDATORS, useExisting: CurrencyInputDirective, multi: true },
        { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: CurrencyInputDirective }
    ],
    standalone: true
})
export class CurrencyInputDirective implements ControlValueAccessor, OnDestroy, Validator {
    private _validator: ValidatorFn | null;
    private _value: number | null = null;
    private _disabled: boolean | null = null;

    /** Whether the last value set on the input was valid. */
    private _lastValueValid = false;

    @Input()
    get value(): number | null {
        return this._value;
    }
    set value(value: number | null) {
        this._lastValueValid = !value || !Number.isNaN(value);
        const oldValue = this.value;
        this._value = value;
        this._formatValue(value);

        if (value !== oldValue) {
            this._valueChange.emit(value);
        }
    }

    @Input()
    get disabled(): boolean {
        return !!this._disabled;
    }
    set disabled(value: boolean) {
        const newValue = coerceBooleanProperty(value);
        const element = this._elementRef.nativeElement;

        if (this._disabled !== newValue) {
            this._disabled = newValue;
            this._disabledChange.emit(newValue);
        }

        // We need to null check the `blur` method, because it's undefined during SSR.
        if (newValue && element.blur) {
            // Normally, native input elements automatically blur if they turn disabled. This behavior
            // is problematic, because it would mean that it triggers another change detection cycle,
            // which then causes a changed after checked error if the input element was focused before.
            element.blur();
        }
    }

    _valueChange = new EventEmitter<number | null>();
    _disabledChange = new EventEmitter<boolean>();

    constructor(
        private _elementRef: ElementRef<HTMLInputElement>,
        @Inject(LOCALE_ID) private _locale: string
    ) {
        this._validator = Validators.compose([this._parseValidator]);
    }

    private _parseValidator: ValidatorFn = (): ValidationErrors | null => {
        return this._lastValueValid ? null : { currencyInputParse: { text: this._elementRef.nativeElement.value } };
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private _validatorOnChange = () => {};

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private _onChange: (value: unknown) => void = () => {};

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private _onTouched = () => {};

    private _formatValue(value: number | null) {
        this._elementRef.nativeElement.value = value ? formatNumber(value, this._locale, '1.2-2') : '';
    }

    writeValue(value: number): void {
        this.value = value;
    }
    registerOnChange(fn: (value: unknown) => void): void {
        this._onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this._onTouched = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    ngOnDestroy(): void {
        this._disabledChange.complete();
    }
    validate(control: AbstractControl): ValidationErrors | null {
        return this._validator ? this._validator(control) : null;
    }
    registerOnValidatorChange(fn: () => void): void {
        this._validatorOnChange = fn;
    }

    _onInput(value: string) {
        let currencyValue: number | null = Number(value);
        this._lastValueValid = REGEX_CURRENCY_NUMBER_INPUT.test(value) && !Number.isNaN(currencyValue);
        currencyValue = this._lastValueValid ? currencyValue : null;

        if (currencyValue !== this._value) {
            this._value = currencyValue;
            this._onChange(currencyValue);
            this._valueChange.emit(currencyValue);
        } else {
            this._validatorOnChange();
        }
    }

    _onBlur() {
        // Reformat the input only if we have a valid value.
        if (this.value) {
            this._formatValue(this.value);
        }
        this._onTouched();
    }
}
