import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogTitle } from '@angular/material/dialog';
import { ProviderSimpleFragment } from '@mymahi/api';
import { environment } from '../../environments/environment';

@Component({
    selector: 'business-redeem-invite-accepted-dialog',
    template: `
        <h2 mat-dialog-title>Invite redeemed</h2>
        <div class="flex flex-col gap-2.5">
            <span class="mat-body h-full w-full flex-auto">Thank you for redeeming an invite to {{ data.provider.title }}.</span>
            <div class="flex-auto">
                <span i18n
                    >If you want to go to a <strong>Mentor dashboard <a href="{{ mentorLink }}">CLICK HERE</a></strong></span
                >
            </div>
            <div class="flex h-full w-full flex-auto flex-row justify-end">
                <button class="flex-none" mat-button [mat-dialog-close]="true">OK</button>
            </div>
        </div>
    `,
    imports: [MatDialogTitle, MatButtonModule, MatDialogClose]
})
export class RedeemInviteAcceptedDialogComponent {
    public mentorLink: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: { provider: ProviderSimpleFragment }) {
        this.mentorLink = `https://app.${environment.baseUrl}/mentor`;
    }
}
