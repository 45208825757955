import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ApolloError } from '@apollo/client/core';
import { MeDetailedQueryGQL, RedeemProviderJoinCodeMutationGQL } from '@mymahi/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RedeemInviteAcceptedDialogComponent } from './redeem-invite-accepted.dialog.component';

@Component({
    selector: 'business-redeem',
    templateUrl: './redeem.component.html',
    styleUrls: ['./redeem.component.scss'],
    imports: [MatCardModule, MatFormFieldModule, MatInputModule, MatButtonModule]
})
export class RedeemComponent implements OnInit, OnDestroy {
    private ngUnsubscribe: Subject<void> = new Subject();

    public redeemingCode = false;

    @ViewChild('code', { static: true })
    public codeInput!: ElementRef<HTMLInputElement>;

    constructor(
        private snackbar: MatSnackBar,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router,
        private redeemProviderJoinCode: RedeemProviderJoinCodeMutationGQL,
        private meDetailed: MeDetailedQueryGQL
    ) {}

    public ngOnInit(): void {
        const code = this.route.snapshot.queryParamMap.get('code');
        if (code != null) {
            this.codeInput.nativeElement.value = code;
        }
    }

    public ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    public redeemInviteCode(code: string): void {
        this.redeemingCode = true;
        this.redeemProviderJoinCode
            .mutate({ input: { code } })
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(
                (res) => {
                    this.redeemingCode = false;
                    this.codeInput.nativeElement.value = '';

                    // Refetch user data to update side menus and such
                    this.meDetailed.fetch().subscribe();

                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    const provider = res.data!.redeemProviderJoinCode.providerMember.provider;
                    this.dialog
                        .open(RedeemInviteAcceptedDialogComponent, {
                            width: '420px',
                            data: {
                                provider
                            }
                        })
                        .afterClosed()
                        .subscribe(() => this.router.navigateByUrl(`/provider/${provider.id}/provider-profile`));
                },
                (_err: ApolloError) => {
                    this.redeemingCode = false;
                    this.snackbar.open('Failed to redeem this invite code, please try again', '', { duration: 4000 });
                }
            );
    }
}
