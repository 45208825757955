<div class="page-container flex flex-col">
    <div class="section-title">Mentor Dashboard</div>
    <mat-card appearance="outlined" class="link-card !flex flex-auto flex-row items-center justify-center">
        <mat-card-content class="!flex flex-row items-center justify-center">
            <div class="flex flex-auto flex-col items-center justify-center">
                <img src="/assets/svg/graphic-teachers.svg" width="400" height="300" />
                <button mat-flat-button color="orange" (click)="openMentorDashboard()">Go to Mentor Dashboard</button>
            </div>
        </mat-card-content>
    </mat-card>
</div>
