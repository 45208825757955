import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router, RouterModule } from '@angular/router';
import { ProviderWithPermissionsFragment } from '@mymahi/api';
import { AuthService } from '@mymahi/authentication';

@Component({
    selector: 'business-navbar-container',
    templateUrl: './container.component.html',
    styleUrls: ['./container.component.scss'],
    imports: [
        CommonModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatSidenavModule,
        MatToolbarModule
    ]
})
export class NavbarContainerComponent {
    @Input() appTitle!: string;
    @Input() providersWithMembership!: ProviderWithPermissionsFragment[];

    constructor(
        private router: Router,
        public authService: AuthService
    ) {}

    public get shouldRenderLoader(): boolean {
        return !this.router.navigated;
    }
}
