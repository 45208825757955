<mat-toolbar class="app-bar mat-elevation-z6">
    <mat-toolbar-row class="flex flex-row">
        <div class="flex-rowflex-initial dashboard-menu flex items-center justify-center">
            <div>
                <img class="icons" src="/assets/svg/mymahi_icon.svg" />
            </div>
            <span class="text">{{ appTitle }}</span>
        </div>
        <mat-divider [vertical]="true"></mat-divider>
        <div class="flex-1 basis-full"></div>
        <mat-divider [vertical]="true"></mat-divider>
        <div class="flex max-w-[5.625rem] flex-1 basis-full flex-row justify-center">
            <a class="flex-none" mat-icon-button color="none" [matMenuTriggerFor]="infoMenu">
                <mat-icon fontSet="fas" fontIcon="fa-info-circle"></mat-icon>
            </a>
            <mat-menu #infoMenu="matMenu">
                <a mat-menu-item href="https://mymahi.com/privacy-policy" target="_blank">
                    <mat-icon fontSet="far" fontIcon="fa-unlock-alt"></mat-icon>
                    <span>Privacy Policy</span>
                </a>
                <a mat-menu-item href="https://mymahi.com/terms-of-use" target="_blank">
                    <mat-icon fontSet="far" fontIcon="fa-file-alt"></mat-icon>
                    <span>Terms of Use</span>
                </a>
            </mat-menu>
        </div>
        <mat-divider [vertical]="true"></mat-divider>

        <div class="flex max-w-[5.625rem] flex-1 basis-full flex-row justify-center">
            <a class="flex-none" mat-icon-button color="none" [matMenuTriggerFor]="signOutMenu">
                <mat-icon fontSet="fas" fontIcon="fa-sign-out"></mat-icon>
            </a>
            <mat-menu #signOutMenu="matMenu">
                <a mat-menu-item [routerLink]="(authService.isAuthenticated$ | async) ? '/auth/logout' : '/auth/login'">{{
                    (authService.isAuthenticated$ | async) ? 'Logout' : 'Login'
                }}</a>
            </mat-menu>
        </div>
    </mat-toolbar-row>
</mat-toolbar>

<div class="app-wrapper">
    <div class="app-container">
        <ng-content select="div.app-left-menu"></ng-content>
        <div class="app-container-content">
            <ng-content></ng-content>
        </div>
    </div>
</div>

@if (shouldRenderLoader) {
    <div class="fullscreen">
        <div class="loader">
            <mat-progress-spinner mode="indeterminate" color="accent"></mat-progress-spinner>
        </div>
    </div>
}
