import { Routes } from '@angular/router';
import { AuthGuard } from '@mymahi/authentication';
import { MentorComponent } from './mentor/mentor.component';
import { RedeemComponent } from './redeem/redeem.component';
import { RedirectToProviderGuard } from './redirectToProvider.guard';

export const routes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                canActivate: [RedirectToProviderGuard],
                children: []
            },
            {
                path: 'provider/:providerId',
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'details'
                    },
                    {
                        path: 'details',
                        loadChildren: () => import('./details/details.routes')
                    },
                    {
                        path: 'my-school-information',
                        loadChildren: () => import('./my-school-information/my-school-information.routes')
                    },
                    {
                        path: 'mentor',
                        component: MentorComponent
                    },
                    {
                        path: 'opportunity',
                        loadChildren: () => import('./opportunity/opportunity.routes')
                    },
                    {
                        path: 'admin',
                        loadChildren: () => import('./admin/admin.routes')
                    },
                    {
                        path: 'newsfeed',
                        loadChildren: () => import('./newsfeed/newsfeed.routes')
                    }
                ]
            },
            {
                path: 'redeem',
                component: RedeemComponent
            }
        ]
    },
    {
        path: '**',
        redirectTo: ''
    }
];
